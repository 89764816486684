import Cookies from "js-cookie";
import { USER_TOKEN_COOKIE, API_KEY_COOKIE } from "@/assets/js/constants";
import * as repositories from "@/repositories/auth";
import * as smtpRepo from "@/repositories/smtp";
import * as Sentry from "@sentry/vue";
import { status, mutation } from "./data";
import { clientRepo, dashboardRepo, partnerRepo } from "@/repositories/index";
import { Role } from "@/helpers/enums/eRole";
import store from "@/store";
import { CHROME_WEB_STORE_EXTENSION_ID } from "@/assets/js/constants.js";

const VUE_APP_MODE = process.env.VUE_APP_MODE;
//#region AUTH

export async function partnerSignUp({}, payload) {
  try {
    const user = await repositories.partnerSignUp(payload);
    Cookies.set(USER_TOKEN_COOKIE, user.uniqueId, { secure: true, sameSite: 'Lax' });
  } catch (e) {
    console.log(e);
    throw e;
  }
}

export async function colaberrySignUp({}, payload) {
  try {
    const user = await repositories.colaberrySignUp(payload);
    Cookies.set(USER_TOKEN_COOKIE, user.token, { secure: true, sameSite: 'Lax' });
  } catch (e) {
    console.log(e);
    throw e;
  }
}

export async function dynamicRegister({}, payload) {
  try {
    const user = await repositories.dynamicRegister(payload);
    Cookies.set(USER_TOKEN_COOKIE, user.token, { secure: true, sameSite: 'Lax' });
  } catch (e) {
    console.log(e);
    throw e;
  }
}

export async function sessionRestore({ commit }, payload) {
  try {
    const user = await repositories.sessionRestore(payload);
    Cookies.set(USER_TOKEN_COOKIE, user.token, { secure: true, sameSite: 'Lax' });
    commit(mutation.SetStatus, status.SuccessRestoreSession);
  } catch (e) {
    commit(mutation.SetStatus, status.FailureRestoreSession);
  }
}

export async function signUp({ commit }, payload) {
  try {
    const user = await repositories.signUp(payload);
    // @ts-ignore
    Cookies.set(USER_TOKEN_COOKIE, user.token, { secure: true, sameSite: 'Lax' });
    commit(mutation.SetUser, user);
  } catch (e) {
    throw e;
  }
}

export async function signIn({}, payload) {
  try {
    const user = await repositories.signIn(payload);
    // @ts-ignore
    Cookies.set(USER_TOKEN_COOKIE, user.token, { secure: true, sameSite: 'Lax' });
  } catch (e) {
    throw e;
  }
}

export async function googleSignIn({}, payload) {
  try {
    const user = await repositories.googleLogin(payload);
    // @ts-ignore
    Cookies.set(USER_TOKEN_COOKIE, user.token, { secure: true, sameSite: 'Lax' });
  } catch (e) {
    throw e;
  }
}

export async function signOut({ commit }) {
  try {
    commit(mutation.SetUser, null);
    Cookies.remove(USER_TOKEN_COOKIE);
    Cookies.remove(API_KEY_COOKIE); // cleanup unused cookie
    Cookies.remove("encryptedPartnerId");
    Sentry.getCurrentScope().setUser(null);
  } catch (e) {
    throw e;
  }
}

export async function auth({ commit }) {
  try {
    if (!Cookies.get(USER_TOKEN_COOKIE)) {
      throw new Error("user is not logged in");
    }
    const authData = await repositories.getAuthData();
    const userInfo = await repositories.getUserInfo();
    commit(mutation.SetUser, { ...userInfo, ...authData });
    commit(mutation.SetInit, true);
  } catch (e) {
    throw e;
  }
}

export async function refreshUserData({ commit }) {
  try {
    const userInfo = await repositories.getUserInfo();
    commit(mutation.UpdateUserData, userInfo);
    commit(mutation.SetStatus, status.SuccessRefreshUserData);
  } catch (e) {
    commit(mutation.SetStatus, status.FailureRefreshUserData);
  }
}

export async function updateUserData({ commit }, payload) {
  try {
    const userInfo = await repositories.getUserInfo();
    commit(mutation.UpdateUserData, {...userInfo, ...payload});
    commit(mutation.SetStatus, status.SuccessUpdateUserData);
  } catch (e) {
    commit(mutation.SetStatus, status.FailureUpdateUserData);
  }
}

export async function updateLoopCount({ commit}, offset ) {
  try {
    commit(mutation.AdjustLoopCount, offset);
  } catch (e) {
    throw e;
  }
}

export async function forgotPassword({}, email) {
  try {
    return await repositories.forgotPassword(email);
  } catch (e) {
    throw e;
  }
}

export async function updatePassword({ commit }, password) {
  try {
    commit(mutation.SetLoading, true);
    commit(mutation.SetStatus, status.ProgressUpdatePassword);
    await repositories.updatePassword(password);
    commit(mutation.SetStatus, status.SuccessUpdatePassword);
  } catch (e) {
    commit(mutation.SetStatus, status.FailureUpdatePassword);
  } finally {
    commit(mutation.SetLoading, false);
  }
}

export async function deleteUser({}) {
  try {
    const res = await repositories.deleteUser();
    if (res === "OK") {
      return res;
    } else {
      throw new Error();
    }
  } catch (e) {
    throw e;
  }
}

export async function updateInfo({}, payload) {
  try {
    return await repositories.updateInfo(payload);
  } catch (e) {
    throw e;
  }
}

export async function resetPassword({ commit }, payload) {
  try {
    const res = await repositories.resetPassword(payload);
    commit(mutation.SetStatus, status.SuccessResetPassword);
  } catch (e) {
    commit(mutation.SetStatus, status.FailureResetPassword);
    throw e;
  }
}
// #endregion

//#region Dashboard
export async function getDashboardUser({}, payload) {
  try {
    const res = await dashboardRepo.getDashboardUser();
    return res;
  } catch (e) {
    throw e;
  }
}

//#endregion

//#region CV

export async function setDefaultCv({ commit }, cvId) {
  try {
    await repositories.setDefaultCv(cvId);
  } catch (e) {
    throw e;
  }
}

export async function getCVs({ commit }, clientId) {
  try {
    const cvs = await repositories.getCvs(clientId || null);
    commit(mutation.SetCvs, cvs);
  } catch (e) {
    throw e;
  }
}

export async function uploadCv({ commit }, payload) {
  try {
    const res = await repositories.uploadCv(payload.file, payload.clientId);
    return res;
  } catch (e) {
    throw e;
  }
}

export async function deleteCV({}, payload) {
  try {
    const res = await repositories.deleteCV(payload.userId, payload.cvId);
    return res;
  } catch (e) {
    throw e;
  }
}

//#endregion

//#region SMTP
export async function updateSmtpSettings({ commit }, payload) {
  try {
    const res = await smtpRepo.updateSmtpSettings(payload);
    return res;
  } catch (e) {
    throw e;
  }
}

export async function deleteSmtpSettings({ commit }, payload) {
  try {
    const res = await smtpRepo.deleteSmtpSettings(payload);
    return res;
  } catch (e) {
    throw e;
  }
}
//#endregion

//#region Inbox
export async function getMyEmails({}, payload) {
  try {
    const res = await dashboardRepo.getMyEmails(payload);
    return res;
  } catch (e) {
    throw e;
  }
}

//#endregion

//#region Application
export async function getMyApplication({}, payload) {
  try {
    const res = await dashboardRepo.getMyApplication(payload);
    return res;
  } catch (e) {
    throw e;
  }
}
//#endregion

//#region Questions
export async function getMyQuestions({}, payload) {
  try {
    let res;
    if (payload.role == Role.ADMIN || payload.role == Role.MANAGER || payload.role == Role.SupportAgent) {
      res = await clientRepo.getQuestions(payload);
    } else if (payload.role == Role.USER || payload.role == Role.MANAGED_USER) {
      res = await dashboardRepo.getQuestions(payload);
    }
    return res;
  } catch (e) {
    throw e;
  }
}

export async function updateQuestion({}, payload) {
  try {
    let res;
    if (payload.role == Role.ADMIN || payload.role == Role.MANAGER || payload.role == Role.SupportAgent) {
      res = await clientRepo.updateQuestion(payload);
    } else if (payload.role == Role.USER || payload.role == Role.MANAGED_USER) {
      res = await dashboardRepo.updateQuestion(payload);
    }

    return res;
  } catch (e) {
    throw e;
  }
}
//#endregion

export async function overviewStats({}, payload) {
  try {
    return await repositories.getOverviewStats();
  } catch (e) {
    throw e;
  }
}

//#region Billing

export async function billing({}) {
  try {
    return await dashboardRepo.billing();
  } catch (e) {
    throw e;
  }
}
//#endregion

export async function getApplications({}, payload) {
  try {
    if (!payload.isEmail) {
      return await partnerRepo.getApplications(payload);
    } else {
      return await partnerRepo.getApplicationsEmails(payload);
    }
  } catch (e) {
    throw e;
  }
}

export async function getSubscription({}, payload) {
  try {
    return await partnerRepo.getSubscription();
  } catch (e) {
    throw e;
  }
}

export async function getB2CSubscription({}) {
  try {
    return await dashboardRepo.getSubscription();
  } catch (e) {
    throw e;
  }
}

export async function updateMatchesInterviewStatus({}, payload) {
  try {
    if (VUE_APP_MODE == "b2c" || payload.isManagedUser) {
      return await dashboardRepo.updateMatchesInterviewStatus(payload);
    } else {
      return await partnerRepo.changeInterviewStatus(payload);
    }
  } catch (e) {
    throw e;
  }
}

export async function subscribePartnerBilling({}, payload) {
  try {
    let res = await partnerRepo.subscribePartnerBilling(payload);

    return res;
  } catch (e) {
    throw e;
  }
}


export async function setJustRegistered({ commit }, payload) {
  try {
    commit(mutation.SetJustRegistered, payload);
  } catch (e) {

    throw e;
  }
}

export async function updateOnboardingSteps({},step) {
  try {
    let res = await dashboardRepo.updateOnboardingSteps(step);
    return res;
  } catch (e) {
    console.log(e)
    throw e;

  }
}


export async function optimisticUpdateOnboardingStep({ commit }, payload) {
  try {
    commit(mutation.OptimisticOnboardingStep, payload);
  } catch (e) {
    throw e;
  }
}

export async function putScreeningQuestions({  }, screeningQuestions) {
  try {
    let res = await dashboardRepo.putScreeningQuestions(screeningQuestions);
    return res;
  } catch (e) {
    throw e;
  }
}

export async function getScreeningQuestions({ }, payload) {
  try {
    return await dashboardRepo.getScreeningQuestions();
  } catch (e) {
    throw e;
  }
}



export async function isExtensionInstalled({commit}) {
  return new Promise((resolve) => {
    if (!window.chrome || !chrome.runtime || !chrome.runtime.sendMessage) {
      console.log('Chrome runtime not available');
      resolve(false);
      return;
    }

    try {
      const timeoutId = setTimeout(() => {
        console.log('Extension check timed out');
        resolve(false);
      }, 5000);

      chrome.runtime.sendMessage(
        CHROME_WEB_STORE_EXTENSION_ID, 
        { type: "hello" }, 
        (response) => {
          clearTimeout(timeoutId);
          
          if (chrome.runtime.lastError) {
            console.log('Extension check error:', chrome.runtime.lastError);
            resolve(false);
            return;
          }
          commit(mutation.SetExtensionInstalled, response?.type === "ack");

          resolve(response?.type === "ack");

        }
      );
    } catch (error) {
      console.log('Extension check failed:', error);
      resolve(false);
    }
  });
}